import React, { useState } from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";
import { UnityAPI, Video } from "~components/api-data";

import {
  Code,
  Response,
  Attribute,
  IL,
  Warning,
  Remark,
  APIMethod,
  VSpace,
  SideBySideCode,
  APIReferenceiTHOR,
} from "~components/documentation";

export default function iTHORDocReference({ data, location }) {
  const [highlightedParam, setHighlightedParam] = useState("");
  return (
    <APIReferenceiTHOR columnKey="initialization" pageName="Initialization">
      <Section sectionTitle="Initialization" emoji="star-struck">
        <p>
          After installing AI2-THOR, we can initialize a controller, which will
          allow us to execute actions in the environment.
        </p>
        <SideBySideCode
          example={[
            `from ai2thor.controller import Controller`,
            ``,
            `controller = Controller(`,
            `    agentMode="default",`,
            `    visibilityDistance=1.5,`,
            `    scene="FloorPlan212",`,
            ``,
            `    # step sizes`,
            `    gridSize=0.25,`,
            `    snapToGrid=True,`,
            `    rotateStepDegrees=90,`,
            ``,
            `    # image modalities`,
            `    renderDepthImage=False,`,
            // `    renderSemanticSegmentation=False,`,
            `    renderInstanceSegmentation=False,`,
            ``,
            `    # camera properties`,
            `    width=300,`,
            `    height=300,`,
            `    fieldOfView=90`,
            `)`,
          ]}
          argsTitle="Controller Parameters"
        >
          <Attribute
            hashPrefix="initialization"
            name="agentMode"
            type="str"
            default='"default"'
            description={
              <p>
                The type of agent that will interact with the scene. For iTHOR,
                stick with <IL>agentMode="default"</IL>. RoboTHOR provides the{" "}
                <IL>locobot</IL> as an <IL>agentMode</IL> option, and
                ManipulaTHOR provides the <IL>arm</IL> as an <IL>agentMode</IL>{" "}
                option.
              </p>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="visibilityDistance"
            type="float"
            default="1.5"
            description={
              <p>
                Used to set the maximum goal distance in meters between the
                agent and an object. This affects each object's <IL>visible</IL>{" "}
                property, where, when <IL>True</IL>, then the agent is within{" "}
                <IL>visibilityDistance</IL> of that object and the object
                appears in the agent's current egocentric frame.
              </p>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="scene"
            type="Optional[str]"
            description={
              <p>
                The name of which scene to initialize. Valid iTHOR scenes are
                listed <Link to="../scenes">here</Link>. If unspecified and the{" "}
                <IL>default</IL> agent is being used, an iTHOR scene will be
                chosen at random.
              </p>
            }
            default="None"
          />
          <Attribute
            hashPrefix="initialization"
            name="gridSize"
            type="float"
            description={
              <p>
                The amount, in meters, that the agent moves after calling a move
                action (e.g., <IL>MoveAhead</IL> and <IL>MoveBack</IL>).
              </p>
            }
            default={0.25}
          />
          <Attribute
            hashPrefix="initialization"
            name="snapToGrid"
            type="bool"
            default="True"
            description={
              <p>
                Determines whether the agent’s position is snapped to a grid
                point after any movement action (e.g., <IL>MoveAhead</IL> and{" "}
                <IL>TeleportFull</IL>). Grid points are spaced out by{" "}
                <IL>gridSize</IL>. Set to <IL>False</IL> to allow diagonal
                movement.
              </p>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="rotateStepDegrees"
            type="float"
            default="90"
            description={
              <p>
                The default amount, in degrees, that the agent rotates after
                calling a rotate action (i.e., <IL>RotateLeft</IL> or{" "}
                <IL>RotateRight</IL>
                ).
              </p>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="renderDepthImage"
            type="bool"
            default="False"
            description={
              <>
                <p>
                  When <IL>True</IL>, a depth frame is rendered and made
                  available as an <IL>event.depth_frame</IL> attribute.
                </p>
                <VSpace s="15px" />
                <Remark>
                  We require this to be explicitly passed in because rendering
                  depth takes longer than rendering RGB alone.
                </Remark>
              </>
            }
          />
          {/* <Attribute
            hashPrefix="initialization"
            name="renderSemanticSegmentation"
            type="bool"
            default="False"
            description={
              <>
                When <IL>True</IL>, a semantic segmentation frame is rendered
                and made available as an{" "}
                <IL>Event.semantic_segmentation_frame</IL> attribute. Semantic
                segmentation provides segmentation by object type, where objects
                of the same type are indistinguishable on the frame.
              </>
            }
          /> */}
          <Attribute
            hashPrefix="initialization"
            name="renderInstanceSegmentation"
            type="bool"
            default="False"
            description={
              <>
                <p>
                  When <IL>True</IL>, a instance segmentation frame is rendered
                  and made available as an{" "}
                  <IL>event.instance_segmentation_frame</IL> attribute. Instance
                  segmentation provides segmentation by object instance, where
                  all objects in view are distinguishable.
                </p>
                <VSpace s="15px" />
                <Remark>
                  We require this to be explicitly passed in because rendering
                  instance segmentation takes longer than rendering RGB alone.
                </Remark>
              </>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="width"
            type="int"
            default={300}
            description={
              <p>
                The number of horizontal sampled pixels for each frame. This
                affects every rendered image frame (e.g., <IL>event.frame</IL>,{" "}
                <IL>event.depth_frame</IL>, <IL>event.cv2img</IL>).
              </p>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="height"
            type="int"
            default={300}
            description={
              <p>
                The number of vertical sampled pixels for each frame. This
                affects every rendered image frame (e.g., <IL>event.frame</IL>,{" "}
                <IL>event.depth_frame</IL>, <IL>event.cv2img</IL>).
              </p>
            }
          />
          <Attribute
            hashPrefix="initialization"
            name="fieldOfView"
            type="int"
            default={90}
            description={
              <>
                <p>
                  Changes the camera's optical field of view, in degrees. Valid
                  values are in the domain <Latex>$$(0:180)$$</Latex>. The
                  default field of view when <IL>agentMode="default"</IL> is 90
                  degrees.
                </p>
                <Video
                  id="554179934"
                  aspectRatio={1}
                  marginTop="15px"
                  marginBottom="15px"
                />
                <Warning>
                  Not all agent modes have the same default <IL>fieldOfView</IL>
                  . For instance, the <IL>locobot</IL> has a default{" "}
                  <IL>fieldOfView</IL> of <Latex>$$60$$</Latex> degrees.
                </Warning>
              </>
            }
          />
        </SideBySideCode>
      </Section>
      <Section sectionTitle="Reset" emoji="new_moon_with_face">
        <p>
          Any{" "}
          <Link to="#initialization">
            <IL>Controller</IL> Initialization
          </Link>{" "}
          parameter can later be changed by calling the reset method. For
          instance, we can call:
        </p>
        <Code>
          controller.reset(scene="FloorPlan319", rotateStepDegrees=30)
        </Code>
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          The values will default to what they were upon the most recent reset
          or initialization. For instance, if you initialized with{" "}
          <IL>fieldOfView=45</IL>, then called <IL>reset</IL> with{" "}
          <IL>fieldOfView=60</IL>, calling <IL>reset</IL> again, without passing
          in <IL>fieldOfView</IL> will keep <IL>fieldOfView=60</IL>.
        </p>
      </Section>
      <Section sectionTitle="Controlling Time" emoji="stopwatch">
        <p>
          By default, AI2-THOR executes high-level actions that provide a
          response from the environment after the action completes execution.
          For instance, if the agent successfully executes an{" "}
          <IL>OpenObject</IL> action, the response is the state of the
          environment after the action has completely finished executing.
        </p>
        <p>
          However, we also provide the ability to observe intermediate results,
          which is discussed in this section.
        </p>
        <SubSection sectionTitle="Pause Physics Auto-Simulation" emoji="snail">
          <p>
            <IL>PausePhysicsAutoSim</IL> toggles off auto-simulation in the
            environment, which must be called before one can control how much
            time passes in-between steps.
          </p>
          <Code lines={['controller.step("PausePhysicsAutoSim")']} />
        </SubSection>
        <SubSection sectionTitle="Advance Physics Step" emoji="point_right">
          <APIMethod
            location={location}
            methodKey="AdvancePhysicsStep"
            parentAPI={UnityAPI.BaseAgent}
            setHighlightedParam={setHighlightedParam}
            highlightedParam={highlightedParam}
            pageQuery={data}
          />
        </SubSection>
        <SubSection
          sectionTitle="Unpause Physics Auto-Simulation"
          emoji="cold_face"
        >
          <p>
            <IL>UnpausePhysicsAutoSim</IL> toggles back on the default physics
            auto-simulation, which returns future events after the action
            finishes execution.
          </p>
          <Warning>
            <p>
              Unpausing auto-simulation while objects are still in motion (i.e.,
              <IL>inMotion=True</IL> on an object's metadata) will freeze each
              object at its current location. Thus, the remaining motion of such
              an object will be lost.
            </p>
            <p>
              To capture the entire movement of an object, do not unpause
              physics with this action until all objects return{" "}
              <IL>inMotion=False</IL> in their object metadata, which can also
              be checked with the shorthand of{" "}
              <IL>event.metadata["isSceneAtRest"]</IL>.
            </p>
          </Warning>
          <Code lines={['controller.step("UnpausePhysicsAutoSim")']} />
        </SubSection>
      </Section>
    </APIReferenceiTHOR>
  );
}
